export const GET_WHITELIST_IPS = "GET_WHITELIST_IPS";
export const GET_WHITELIST_IPS_SUCCESS = "GET_WHITELIST_IPS_SUCCESS";
export const GET_WHITELIST_IPS_ERROR = "GET_WHITELIST_IPS_ERROR";
export const GET_WHITELIST_IP_DATA = "GET_WHITELIST_IP_DATA";
export const GET_WHITELIST_IP_DATA_SUCCESS = "GET_WHITELIST_IP_DATA_SUCCESS";
export const GET_WHITELIST_IP_DATA_ERROR = "GET_WHITELIST_IP_DATA_ERROR";
export const SAVE_WHITELIST_IP_DATA = "SAVE_WHITELIST_IP_DATA";
export const SAVE_WHITELIST_IP_DATA_SUCCESS = "SAVE_WHITELIST_IP_DATA_SUCCESS";
export const SAVE_WHITELIST_IP_DATA_ERROR = "SAVE_WHITELIST_IP_DATA_ERROR";
export const DELETE_WHITELIST_IP = "DELETE_WHITELIST_IP";
export const DELETE_WHITELIST_IP_SUCCESS = "DELETE_WHITELIST_IP_SUCCESS";
export const DELETE_WHITELIST_IP_ERROR = "DELETE_WHITELIST_IP_ERROR";