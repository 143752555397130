import { 
        GET_STATES, GET_STATES_SUCCESS, GET_STATES_ERROR,
        GET_STATE_DATA, GET_STATE_DATA_SUCCESS, GET_STATE_DATA_ERROR,
        SAVE_STATE_DATA, SAVE_STATE_DATA_SUCCESS, SAVE_STATE_DATA_ERROR,
        DELETE_STATE, DELETE_STATE_SUCCESS, DELETE_STATE_ERROR
       } from "../actions/states";
import apiCall from "../../utils/api";

const state = { status: "" };

const getters = {
    getStatesActionStatus: state => state.status
};

const actions = {
    [GET_STATES]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_STATES);
            apiCall({ url: "gala/states", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_STATES_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_STATES_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_STATES_ERROR);
                reject(err);
            });
        });
    },
    [GET_STATE_DATA]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(GET_STATE_DATA);
            apiCall({ url: "gala/states/" + id, method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_STATE_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_STATE_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_STATE_DATA_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_STATE_DATA]: ({commit}, data) => {
        let saveUrl = "";
        let saveMethod = "";
            if(data.id) {
                saveUrl = "gala/states/" + data.id;
                saveMethod = "PUT";
            }
            else {
                saveUrl = "gala/states";
                saveMethod = "POST";
            }
        return new Promise((resolve, reject) => {
            commit(SAVE_STATE_DATA);
            apiCall({ url: saveUrl, data: data, method: saveMethod })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_STATE_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(SAVE_STATE_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_STATE_DATA_ERROR);
                reject(err);
            });
        });
    },
    [DELETE_STATE]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(DELETE_STATE);
            apiCall({ url: "gala/states/" + id, method: "DELETE" })
            .then(resp => {
                if(resp.data.error) {
                    commit(DELETE_STATE_ERROR);
                    resolve(resp);
                }
                else {
                    commit(DELETE_STATE_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(DELETE_STATE_ERROR);
                reject(err);
            });
        });
    }
}

const mutations = {
    [GET_STATES]: state => {
        state.status = "A carregar...";
    },
    [GET_STATES_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_STATES_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_STATE_DATA]: state => {
        state.status = "A carregar...";
    },
    [GET_STATE_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_STATE_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_STATE_DATA]: state => {
        state.status = "A salvar...";
    },
    [SAVE_STATE_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_STATE_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [DELETE_STATE]: state => {
        state.status = "A eliminar...";
    },
    [DELETE_STATE_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [DELETE_STATE_ERROR]: state => {
        state.status = "Erro";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};