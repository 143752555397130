import { 
        GET_TABLES, GET_TABLES_SUCCESS, GET_TABLES_ERROR,
        GET_TABLE_DATA, GET_TABLE_DATA_SUCCESS, GET_TABLE_DATA_ERROR,
        SAVE_TABLE_DATA, SAVE_TABLE_DATA_SUCCESS, SAVE_TABLE_DATA_ERROR,
        GET_TABLE_SELECT_OPTIONS, GET_TABLE_SELECT_OPTIONS_SUCCESS, GET_TABLE_SELECT_OPTIONS_ERROR,
        DELETE_TABLE, DELETE_TABLE_SUCCESS, DELETE_TABLE_ERROR
       } from "../actions/tables";
import apiCall from "../../utils/api";

const state = { status: "" };

const getters = {
    getTablesActionStatus: state => state.status
};

const actions = {
    [GET_TABLES]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_TABLES);
            apiCall({ url: "gala/tables", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_TABLES_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_TABLES_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_TABLES_ERROR);
                reject(err);
            });
        });
    },
    [GET_TABLE_DATA]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(GET_TABLE_DATA);
            apiCall({ url: "gala/tables/" + id, method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_TABLE_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_TABLE_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_TABLE_DATA_ERROR);
                reject(err);
            });
        });
    },
    [GET_TABLE_SELECT_OPTIONS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_TABLE_SELECT_OPTIONS);
            apiCall({ url: "gala/tables/selectsoptions", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_TABLE_SELECT_OPTIONS_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_TABLE_SELECT_OPTIONS_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_TABLE_SELECT_OPTIONS_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_TABLE_DATA]: ({commit}, data) => {
        let saveUrl = "";
        let saveMethod = "";
            if(data.id) {
                saveUrl = "gala/tables/" + data.id;
                saveMethod = "PUT";
            }
            else {
                saveUrl = "gala/tables";
                saveMethod = "POST";
            }
        return new Promise((resolve, reject) => {
            commit(SAVE_TABLE_DATA);
            apiCall({ url: saveUrl, data: data, method: saveMethod })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_TABLE_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(SAVE_TABLE_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_TABLE_DATA_ERROR);
                reject(err);
            });
        });
    },
    [DELETE_TABLE]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(DELETE_TABLE);
            apiCall({ url: "gala/tables/" + id, method: "DELETE" })
            .then(resp => {
                if(resp.data.error) {
                    commit(DELETE_TABLE_ERROR);
                    resolve(resp);
                }
                else {
                    commit(DELETE_TABLE_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(DELETE_TABLE_ERROR);
                reject(err);
            });
        });
    }
}

const mutations = {
    [GET_TABLES]: state => {
        state.status = "A carregar...";
    },
    [GET_TABLES_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_TABLES_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_TABLE_DATA]: state => {
        state.status = "A carregar...";
    },
    [GET_TABLE_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_TABLE_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_TABLE_DATA]: state => {
        state.status = "A salvar...";
    },
    [SAVE_TABLE_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_TABLE_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_TABLE_SELECT_OPTIONS]: state => {
        state.status = "A carregar...";
    },
    [GET_TABLE_SELECT_OPTIONS_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_TABLE_SELECT_OPTIONS_ERROR]: state => {
        state.status = "Erro";
    },
    [DELETE_TABLE]: state => {
        state.status = "A eliminar...";
    },
    [DELETE_TABLE_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [DELETE_TABLE_ERROR]: state => {
        state.status = "Erro";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};