import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_DESTROY,
    AUTH_INITIALISE,
    AUTH_UPDATE_TOKEN_EXPIRATION,
    AUTH_UPDATE_USER_IMAGE,
    AUTH_NO_ACCESS
} from "../actions/auth";
import apiCall from "../../utils/api";

let state = {
    status: false,
    token: "",
    token_duration: "",
    user_id: "",
    user_name: "",
    user_role: "",
    user_username: "",
    user_image: "",
    expiration: ""
};

const getters = {
    isAuthenticated: state => {
        const currentDate = new Date().getTime();
        const expirationDate = new Date(state.expiration).getTime();
        if (!!expirationDate && (currentDate < expirationDate) && (!!state.token)) {
            return true;
        }
        // token doesn't exist or expired, so clean data from localStorage
        if(localStorage.getItem("authData")) {
            localStorage.removeItem("authData");
        }
        return false;
    },
    isAdmin: state => state.user_role == "admin",
    getAuthToken: state => state.token,
    getAuthTokenExpiration: state => state.expiration,
    getAuthTokenDuration: state => state.token_duration,
    getAuthStatus: state => state.status,
    getAuthUserId: state => state.user_id,
    getAuthUserName: state => state.user_name,
    getAuthUserImage: state => state.user_image,
    getAuthUserRole: state => state.user_role,
    getAuthUserUsername: state => state.user_username
};

const actions = {
    [AUTH_INITIALISE]: ({ commit }) => {
        commit(AUTH_INITIALISE);
    },
    [AUTH_UPDATE_TOKEN_EXPIRATION]: ({ commit }) => {
        commit(AUTH_UPDATE_TOKEN_EXPIRATION);
    },
    [AUTH_NO_ACCESS]: ({ commit }) => {
        commit(AUTH_NO_ACCESS);
    },
    [AUTH_REQUEST]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            apiCall({ url: "api/login", data: data, method: "POST" })
            .then(resp => {
                if(resp.data.error) {
                    commit(AUTH_ERROR);
                    resolve(resp);
                }
                else {
                    commit(AUTH_SUCCESS, resp.data);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(AUTH_ERROR);
                reject(err);
            });
        });
    },
    [AUTH_DESTROY]: ({ commit }) => {
        return new Promise(resolve => {
            apiCall({ url: "api/logout", method: "GET" })
            .then(() => {
                commit(AUTH_DESTROY);
            })
            .catch(() => {
                commit(AUTH_DESTROY);
            });
            commit(AUTH_DESTROY);
            resolve();
        });
    }
};

const mutations = {
    [AUTH_INITIALISE]: (state) => {
        // Necessary to keep state if page is refreshed
        if(!state.token && localStorage.getItem("authData")) {
            let data = JSON.parse(localStorage.getItem("authData"));
            Object.entries(data).forEach(([key, value]) => {
                state[key] = value;
            })
        }
    },
    [AUTH_REQUEST]: (state) => {
        state.status = false;
    },
    [AUTH_SUCCESS]: (state, result) => {
        state.status = true;
        state.token = result.access_token;
        state.token_duration = result.token_duration;
        state.user_id = result.user_id;
        state.user_name = result.user_name;
        state.user_role = result.user_role;
        state.user_username = result.user_username;
        state.user_image = result.user_image;
        state.expiration = result.expiration;
        localStorage.setItem("authData", JSON.stringify(state));
    },
    [AUTH_ERROR]: (state) => {
        state.status = false;
        state.token = "";
        state.token_duration = "";
        state.user_id = "";
        state.user_name = "";
        state.user_role = "";
        state.user_username = "";
        state.user_image = "";
        state.expiration = "";
        localStorage.removeItem("authData");
    },
    [AUTH_DESTROY]: (state) => {
        state.status = false;
        state.token = "";
        state.token_duration = "";
        state.user_id = "";
        state.user_name = "";
        state.user_role = "";
        state.user_username = "";
        state.user_image = "";
        state.expiration = "";
        localStorage.removeItem("authData");
    },
    [AUTH_NO_ACCESS]: (state) => {
        state.status = false;
    },
    [AUTH_UPDATE_USER_IMAGE]: (state, result) => {
        // If updated user is the logged in user, update the image
        if(state.user_id == result.data.id) {
            state.user_image = result.data.image;
            localStorage.removeItem("authData");
            localStorage.setItem("authData", JSON.stringify(state));
        }
    },
    [AUTH_UPDATE_TOKEN_EXPIRATION]: (state) => {
        const newExpiration = new Date(Date.now() + (state.token_duration * 1000));
        state.expiration = newExpiration.toISOString().slice(0, 19).replace('T', ' ');
        localStorage.removeItem("authData");
        localStorage.setItem("authData", JSON.stringify(state));
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};