import axios from 'axios';
import store from "../store";

const apiCall = ({ url, data, method }) =>
    new Promise((resolve, reject) => {
        try {
            const headers = setHeaders(url);
            const apiBaseUrl = store.getters.getApiBaseUrl;
            let config = {
                validateStatus: null,
                method: method,
                data: data,
                headers: headers
            };
            axios.request(apiBaseUrl + url, config)
                .then(resp => {
                    resolve(resp);
                }, err => {
                    reject(err);
                });
        } catch (err) {
            reject(err);
        }
    });

function isAuthTokenValid() {
    const expirationDate = new Date(store.getters.getAuthTokenExpiration).getTime();
    const currentDate = new Date().getTime();
    const tokenValid = (currentDate < expirationDate) && (!!store.getters.getAuthToken);
    if (!tokenValid) {
        return false;
    }
    return true;
}

function setHeaders(url) {
    let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    };
    if(url !== "login" && isAuthTokenValid()) {
        headers['Authorization'] = "Bearer "+ store.getters.getAuthToken;
    }
    return headers;
}

export default apiCall;
