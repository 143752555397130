export const GET_TYPES = "GET_TYPES";
export const GET_TYPES_SUCCESS = "GET_TYPES_SUCCESS";
export const GET_TYPES_ERROR = "GET_TYPES_ERROR";
export const GET_TYPE_DATA = "GET_TYPE_DATA";
export const GET_TYPE_DATA_SUCCESS = "GET_TYPE_DATA_SUCCESS";
export const GET_TYPE_DATA_ERROR = "GET_TYPE_DATA_ERROR";
export const SAVE_TYPE_DATA = "SAVE_TYPE_DATA";
export const SAVE_TYPE_DATA_SUCCESS = "SAVE_TYPE_DATA_SUCCESS";
export const SAVE_TYPE_DATA_ERROR = "SAVE_TYPE_DATA_ERROR";
export const DELETE_TYPE = "DELETE_TYPE";
export const DELETE_TYPE_SUCCESS = "DELETE_TYPE_SUCCESS";
export const DELETE_TYPE_ERROR = "DELETE_TYPE_ERROR";