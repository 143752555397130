import { 
        GET_WHITELIST_IPS, GET_WHITELIST_IPS_SUCCESS, GET_WHITELIST_IPS_ERROR,
        GET_WHITELIST_IP_DATA, GET_WHITELIST_IP_DATA_SUCCESS, GET_WHITELIST_IP_DATA_ERROR,
        SAVE_WHITELIST_IP_DATA, SAVE_WHITELIST_IP_DATA_SUCCESS, SAVE_WHITELIST_IP_DATA_ERROR,
        DELETE_WHITELIST_IP, DELETE_WHITELIST_IP_SUCCESS, DELETE_WHITELIST_IP_ERROR
       } from "../actions/whitelistips";
import apiCall from "../../utils/api";

const state = { status: "" };

const getters = {
    getwhitelistipsActionStatus: state => state.status
};

const actions = {
    [GET_WHITELIST_IPS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_WHITELIST_IPS);
            apiCall({ url: "api/whitelistips", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_WHITELIST_IPS_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_WHITELIST_IPS_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_WHITELIST_IPS_ERROR);
                reject(err);
            });
        });
    },
    [GET_WHITELIST_IP_DATA]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(GET_WHITELIST_IP_DATA);
            apiCall({ url: "api/whitelistips/" + id, method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_WHITELIST_IP_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_WHITELIST_IP_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_WHITELIST_IP_DATA_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_WHITELIST_IP_DATA]: ({commit}, data) => {
        let saveUrl = "";
        let saveMethod = "";
            if(data.id) {
                saveUrl = "api/whitelistips/" + data.id;
                saveMethod = "PUT";
            }
            else {
                saveUrl = "api/whitelistips";
                saveMethod = "POST";
            }
        return new Promise((resolve, reject) => {
            commit(SAVE_WHITELIST_IP_DATA);
            apiCall({ url: saveUrl, data: data, method: saveMethod })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_WHITELIST_IP_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(SAVE_WHITELIST_IP_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_WHITELIST_IP_DATA_ERROR);
                reject(err);
            });
        });
    },
    [DELETE_WHITELIST_IP]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(DELETE_WHITELIST_IP);
            apiCall({ url: "api/whitelistips/" + id, method: "DELETE" })
            .then(resp => {
                if(resp.data.error) {
                    commit(DELETE_WHITELIST_IP_ERROR);
                    resolve(resp);
                }
                else {
                    commit(DELETE_WHITELIST_IP_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(DELETE_WHITELIST_IP_ERROR);
                reject(err);
            });
        });
    }
}

const mutations = {
    [GET_WHITELIST_IPS]: state => {
        state.status = "A carregar...";
    },
    [GET_WHITELIST_IPS_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_WHITELIST_IPS_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_WHITELIST_IP_DATA]: state => {
        state.status = "A carregar...";
    },
    [GET_WHITELIST_IP_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_WHITELIST_IP_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_WHITELIST_IP_DATA]: state => {
        state.status = "A salvar...";
    },
    [SAVE_WHITELIST_IP_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_WHITELIST_IP_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [DELETE_WHITELIST_IP]: state => {
        state.status = "A eliminar...";
    },
    [DELETE_WHITELIST_IP_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [DELETE_WHITELIST_IP_ERROR]: state => {
        state.status = "Erro";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};