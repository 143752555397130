import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from './store';
import Vuelidate from 'vuelidate';
import Snotify, { SnotifyPosition } from 'vue-snotify';

Vue.config.productionTip = false;

const snotifyOptions = {
    toast: {
        position: SnotifyPosition.centerTop,
        timeout: 2000,
        showProgressBar: true,
        closeOnClick: true
    }
};

Vue.use(Vuelidate);
Vue.use(Snotify, snotifyOptions);

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');
