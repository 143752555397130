export const GET_BLACKLIST_IPS = "GET_BLACKLIST_IPS";
export const GET_BLACKLIST_IPS_SUCCESS = "GET_BLACKLIST_IPS_SUCCESS";
export const GET_BLACKLIST_IPS_ERROR = "GET_BLACKLIST_IPS_ERROR";
export const GET_BLACKLIST_IP_DATA = "GET_BLACKLIST_IP_DATA";
export const GET_BLACKLIST_IP_DATA_SUCCESS = "GET_BLACKLIST_IP_DATA_SUCCESS";
export const GET_BLACKLIST_IP_DATA_ERROR = "GET_BLACKLIST_IP_DATA_ERROR";
export const SAVE_BLACKLIST_IP_DATA = "SAVE_BLACKLIST_IP_DATA";
export const SAVE_BLACKLIST_IP_DATA_SUCCESS = "SAVE_BLACKLIST_IP_DATA_SUCCESS";
export const SAVE_BLACKLIST_IP_DATA_ERROR = "SAVE_BLACKLIST_IP_DATA_ERROR";
export const DELETE_BLACKLIST_IP = "DELETE_BLACKLIST_IP";
export const DELETE_BLACKLIST_IP_SUCCESS = "DELETE_BLACKLIST_IP_SUCCESS";
export const DELETE_BLACKLIST_IP_ERROR = "DELETE_BLACKLIST_IP_ERROR";