import { 
        GET_TYPES, GET_TYPES_SUCCESS, GET_TYPES_ERROR,
        GET_TYPE_DATA, GET_TYPE_DATA_SUCCESS, GET_TYPE_DATA_ERROR,
        SAVE_TYPE_DATA, SAVE_TYPE_DATA_SUCCESS, SAVE_TYPE_DATA_ERROR,
        DELETE_TYPE, DELETE_TYPE_SUCCESS, DELETE_TYPE_ERROR
       } from "../actions/types";
import apiCall from "../../utils/api";

const state = { status: "" };

const getters = {
    getTypesActionStatus: state => state.status
};

const actions = {
    [GET_TYPES]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_TYPES);
            apiCall({ url: "gala/types", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_TYPES_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_TYPES_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_TYPES_ERROR);
                reject(err);
            });
        });
    },
    [GET_TYPE_DATA]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(GET_TYPE_DATA);
            apiCall({ url: "gala/types/" + id, method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_TYPE_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_TYPE_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_TYPE_DATA_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_TYPE_DATA]: ({commit}, data) => {
        let saveUrl = "";
        let saveMethod = "";
            if(data.id) {
                saveUrl = "gala/types/" + data.id;
                saveMethod = "PUT";
            }
            else {
                saveUrl = "gala/types";
                saveMethod = "POST";
            }
        return new Promise((resolve, reject) => {
            commit(SAVE_TYPE_DATA);
            apiCall({ url: saveUrl, data: data, method: saveMethod })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_TYPE_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(SAVE_TYPE_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_TYPE_DATA_ERROR);
                reject(err);
            });
        });
    },
    [DELETE_TYPE]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(DELETE_TYPE);
            apiCall({ url: "gala/types/" + id, method: "DELETE" })
            .then(resp => {
                if(resp.data.error) {
                    commit(DELETE_TYPE_ERROR);
                    resolve(resp);
                }
                else {
                    commit(DELETE_TYPE_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(DELETE_TYPE_ERROR);
                reject(err);
            });
        });
    }
}

const mutations = {
    [GET_TYPES]: state => {
        state.status = "A carregar...";
    },
    [GET_TYPES_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_TYPES_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_TYPE_DATA]: state => {
        state.status = "A carregar...";
    },
    [GET_TYPE_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_TYPE_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_TYPE_DATA]: state => {
        state.status = "A salvar...";
    },
    [SAVE_TYPE_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_TYPE_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [DELETE_TYPE]: state => {
        state.status = "A eliminar...";
    },
    [DELETE_TYPE_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [DELETE_TYPE_ERROR]: state => {
        state.status = "Erro";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};