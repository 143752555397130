export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";
export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const SAVE_USER_DATA_SUCCESS = "SAVE_USER_DATA_SUCCESS";
export const SAVE_USER_DATA_ERROR = "SAVE_USER_DATA_ERROR";
export const GET_USER_SELECT_OPTIONS = "GET_USER_SELECT_OPTIONS";
export const GET_USER_SELECT_OPTIONS_SUCCESS = "GET_USER_SELECT_OPTIONS_SUCCESS";
export const GET_USER_SELECT_OPTIONS_ERROR = "GET_USER_SELECT_OPTIONS_ERROR";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const CLEAN_STATE = "CLEAN_STATE";