export const GET_PARTICIPANTS = "GET_PARTICIPANTS";
export const GET_PARTICIPANTS_SUCCESS = "GET_PARTICIPANTS_SUCCESS";
export const GET_PARTICIPANTS_ERROR = "GET_PARTICIPANTS_ERROR";
export const GET_PARTICIPANT_DATA = "GET_PARTICIPANT_DATA";
export const GET_PARTICIPANT_DATA_SUCCESS = "GET_PARTICIPANT_DATA_SUCCESS";
export const GET_PARTICIPANT_DATA_ERROR = "GET_PARTICIPANT_DATA_ERROR";
export const SAVE_PARTICIPANT_DATA = "SAVE_PARTICIPANT_DATA";
export const SAVE_PARTICIPANT_DATA_SUCCESS = "SAVE_PARTICIPANT_DATA_SUCCESS";
export const SAVE_PARTICIPANT_DATA_ERROR = "SAVE_PARTICIPANT_DATA_ERROR";
export const SAVE_PARTICIPANT_CHECKIN = "SAVE_PARTICIPANT_CHECKIN";
export const SAVE_PARTICIPANT_CHECKIN_SUCCESS = "SAVE_PARTICIPANT_CHECKIN_SUCCESS";
export const SAVE_PARTICIPANT_CHECKIN_ERROR = "SAVE_PARTICIPANT_CHECKIN_ERROR";
export const GET_PARTICIPANT_SELECT_OPTIONS = "GET_PARTICIPANT_SELECT_OPTIONS";
export const GET_PARTICIPANT_SELECT_OPTIONS_SUCCESS = "GET_PARTICIPANT_SELECT_OPTIONS_SUCCESS";
export const GET_PARTICIPANT_SELECT_OPTIONS_ERROR = "GET_PARTICIPANT_SELECT_OPTIONS_ERROR";
export const DELETE_PARTICIPANT = "DELETE_PARTICIPANT";
export const DELETE_PARTICIPANT_SUCCESS = "DELETE_PARTICIPANT_SUCCESS";
export const DELETE_PARTICIPANT_ERROR = "DELETE_PARTICIPANT_ERROR";
export const SAVE_PARTICIPANTS_TABLE = "SAVE_PARTICIPANTS_TABLE";
export const SAVE_PARTICIPANTS_TABLE_SUCCESS = "SAVE_PARTICIPANTS_TABLE_SUCCESS";
export const SAVE_PARTICIPANTS_TABLE_ERROR = "SAVE_PARTICIPANTS_TABLE_ERROR";