import { 
        GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR,
        GET_USER_DATA, GET_USER_DATA_SUCCESS, GET_USER_DATA_ERROR,
        SAVE_USER_DATA, SAVE_USER_DATA_SUCCESS, SAVE_USER_DATA_ERROR,
        GET_USER_SELECT_OPTIONS, GET_USER_SELECT_OPTIONS_SUCCESS, GET_USER_SELECT_OPTIONS_ERROR,
        DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR,
        CLEAN_STATE
       } from "../actions/users";
import {AUTH_UPDATE_USER_IMAGE} from "../actions/auth";
import apiCall from "../../utils/api";

const state = { status: "" };

const getters = {
    getUsersActionStatus: state => state.status
};

const actions = {
    [GET_USERS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_USERS);
            apiCall({ url: "api/users", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_USERS_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_USERS_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_USERS_ERROR);
                reject(err);
            });
        });
    },
    [GET_USER_DATA]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(GET_USER_DATA);
            apiCall({ url: "api/users/" + id, method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_USER_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_USER_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_USER_DATA_ERROR);
                reject(err);
            });
        });
    },
    [GET_USER_SELECT_OPTIONS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_USER_SELECT_OPTIONS);
            apiCall({ url: "api/users/selectsoptions", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_USER_SELECT_OPTIONS_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_USER_SELECT_OPTIONS_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_USER_SELECT_OPTIONS_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_USER_DATA]: ({commit}, data) => {
        let saveUrl = "";
        let saveMethod = "";
        if (data.id) {
            saveUrl = "api/users/" + data.id;
            saveMethod = "PUT";
        }
        else {
            saveUrl = "api/users";
            saveMethod = "POST";
        }
        return new Promise((resolve, reject) => {
            commit(SAVE_USER_DATA);
            apiCall({ url: saveUrl, data: data, method: saveMethod })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_USER_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(AUTH_UPDATE_USER_IMAGE, resp);
                    commit(SAVE_USER_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_USER_DATA_ERROR);
                reject(err);
            });
        });
    },
    [DELETE_USER]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(DELETE_USER);
            apiCall({ url: "api/users/" + id, method: "DELETE" })
            .then(resp => {
                if(resp.data.error) {
                    commit(DELETE_USER_ERROR);
                    resolve(resp);
                }
                else {
                    commit(DELETE_USER_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(DELETE_USER_ERROR);
                reject(err);
            });
        });
    }
}

const mutations = {
    [GET_USERS]: state => {
        state.status = "A carregar...";
    },
    [GET_USERS_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_USERS_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_USER_DATA]: state => {
        state.status = "A carregar...";
    },
    [GET_USER_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_USER_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_USER_DATA]: state => {
        state.status = "A salvar...";
    },
    [SAVE_USER_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_USER_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_USER_SELECT_OPTIONS]: state => {
        state.status = "A carregar...";
    },
    [GET_USER_SELECT_OPTIONS_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_USER_SELECT_OPTIONS_ERROR]: state => {
        state.status = "Erro";
    },
    [DELETE_USER]: state => {
        state.status = "A eliminar...";
    },
    [DELETE_USER_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [DELETE_USER_ERROR]: state => {
        state.status = "Erro";
    },
    [CLEAN_STATE]: state => {
        state.status = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};