<template>
    <li v-if="isAuthenticated" class="nav-item dropdown dropdown-user">
        <router-link to='#' class="navbar-nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-hover="dropdown" aria-expanded="false">
            <img :src="authUserImage" class="rounded-circle mr-2" height="34">
            <span>{{ authUsername }}</span>
        </router-link>
        <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{name: 'participants'}" class="dropdown-item">
                <i class="icon-address-book"></i> Participantes
            </router-link>
            <router-link :to="{name: 'user', params: {id : authUserId}}" class="dropdown-item">
                <i class="icon-user"></i> Perfil
            </router-link>
            <div v-if="isAdmin" class="dropdown-submenu dropdown-submenu-left">
                <a href="#" class="dropdown-item" data-toggle="dropdown" data-hover="dropdown"><i class="icon-cube3 mr-2"></i> Configurações</a>
                <div class="dropdown-menu">
                    <router-link v-if="isAdmin" :to="{name: 'users'}" class="dropdown-item">
                        <i class="icon-people"></i> Utilizadores
                    </router-link>
                    <router-link v-if="isAdmin" :to="{name: 'states'}" class="dropdown-item">
                        <i class="icon-cog52"></i> Estados
                    </router-link>
                    <router-link v-if="isAdmin" :to="{name: 'types'}" class="dropdown-item">
                        <i class="icon-cog3"></i> Tipos
                    </router-link>
                    <router-link v-if="isAdmin" :to="{name: 'tables'}" class="dropdown-item">
                        <i class="icon-grid7"></i> Mesas
                    </router-link>
                </div>
            </div>
            <div v-if="isAdmin" class="dropdown-submenu dropdown-submenu-left">
                <a href="#" class="dropdown-item" data-toggle="dropdown" data-hover="dropdown"><i class="icon-cube3 mr-2"></i> Administração</a>
                <div class="dropdown-menu">
                    <router-link v-if="isAdmin" :to="{name: 'blacklistips'}" class="dropdown-item">
                        <i class="icon-lock5"></i> Ip's BlackList
                    </router-link>
                    <router-link v-if="isAdmin" :to="{name: 'whitelistips'}" class="dropdown-item">
                        <i class="icon-unlocked2"></i> Ip's WhiteList
                    </router-link>
                </div>
            </div>
            <div class="dropdown-divider"></div>
            <router-link :to="{name: 'logout'}" class="dropdown-item" @click.native="logout()">
                <i class="icon-switch2"></i> Logout
            </router-link>
        </div>
    </li>
    <li v-else>
        <router-link :to="{name: 'login'}" class="dropdown-item">
            <i class="icon-enter"></i> Login
        </router-link>
    </li>
</template>

<script>
import { AUTH_DESTROY } from "../store/actions/auth";

export default {
    name: 'Navigation',
    methods: {
        logout () {
            this.$store.dispatch(AUTH_DESTROY)
            .then(() => {
                this.$router.push('/login');
            })
            .catch(() => {
                this.$router.push('/login');
            })
        }
    },
    computed: {
        isAuthenticated () {
            return this.$store.getters.isAuthenticated;
        },
        authUsername () {
            return this.$store.getters.getAuthUserUsername;
        },
        authUserImage () {
            return this.$store.getters.getApiBaseUrl + this.$store.getters.getApiUsersImageFolder + this.$store.getters.getAuthUserImage;
        },
        authUserId () {
            return this.$store.getters.getAuthUserId;
        },
        isAdmin () {
            return this.$store.getters.isAdmin;
        },
    }
}
</script>
