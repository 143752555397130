import Vue from "vue"
import Router from "vue-router"
import store from "../store"

Vue.use(Router)

const ifAuthenticaded = (to, from, next) => {
    store.commit('AUTH_INITIALISE');
    if (store.getters.isAuthenticated) {
        store.commit('AUTH_UPDATE_TOKEN_EXPIRATION');
        next();
        return;
    }
    store.commit('AUTH_DESTROY');
    next({ name: 'login' });
};

const ifAdmin = (to, from, next) => {
    store.commit('AUTH_INITIALISE');
    if (store.getters.isAuthenticated && store.getters.isAdmin) {
        store.commit('AUTH_UPDATE_TOKEN_EXPIRATION');
        next();
        return;
    }
    store.commit('AUTH_NO_ACCESS');
    next({ name: 'home' });
};

const invalidRoute = (to, from, next) => {
    store.commit('AUTH_INITIALISE');
    next({ name: 'home' });
};

export default new Router({
    mode: "history",
    routes: [
        {   path: '/',
            component: () => import('@/components/home.vue'),
            name: 'home'
        },
        {   path: '/login',
            component: () => import('@/components/login.vue'),
            name: 'login'
        },
        {   path: '/logout',
            name: 'logout',
            beforeEnter: ifAuthenticaded
        },
        // WhiteListips
        {   path: '/whitelistips',
            component: () => import('@/components/whitelistips/whitelistips.vue'),
            name: 'whitelistips',
            beforeEnter: ifAdmin
        },
        {   path: '/whitelistip/:id',
            component: () => import('@/components/whitelistips/whitelistip.vue'),
            name: 'whitelistip',
            beforeEnter: ifAdmin
        },
        {   path: '/whitelistip',
            component: () => import('@/components/whitelistips/whitelistip.vue'),
            name: 'whitelistip-new',
            beforeEnter: ifAdmin
        },
        // BlackListips
        {   path: '/blacklistips',
            component: () => import('@/components/blacklistips/blacklistips.vue'),
            name: 'blacklistips',
            beforeEnter: ifAdmin
        },
        {   path: '/blacklistip/:id',
            component: () => import('@/components/blacklistips/blacklistip.vue'),
            name: 'blacklistip',
            beforeEnter: ifAdmin
        },
        {   path: '/blacklistip',
            component: () => import('@/components/blacklistips/blacklistip.vue'),
            name: 'blacklistip-new',
            beforeEnter: ifAdmin
        },
        // Users
        {   path: '/utilizadores',
            component: () => import('@/components/users/users.vue'),
            name: 'users',
            beforeEnter: ifAdmin
        },
        {   path: '/utilizador/:id',
            component: () => import('@/components/users/user.vue'),
            name: 'user',
            beforeEnter: ifAuthenticaded
        },
        {   path: '/utilizador',
            component: () => import('@/components/users/user.vue'),
            name: 'user-new',
            beforeEnter: ifAdmin
        },
        // States
        {   path: '/estados',
            component: () => import('@/components/states/states.vue'),
            name: 'states',
            beforeEnter: ifAdmin
        },
        {   path: '/estado/:id',
            component: () => import('@/components/states/state.vue'),
            name: 'state',
            beforeEnter: ifAdmin
        },
        {   path: '/estado',
            component: () => import('@/components/states/state.vue'),
            name: 'state-new',
            beforeEnter: ifAdmin
        },
        // Types
        {   path: '/tipos',
            component: () => import('@/components/types/types.vue'),
            name: 'types',
            beforeEnter: ifAdmin
        },
        {   path: '/tipo/:id',
            component: () => import('@/components/types/type.vue'),
            name: 'type',
            beforeEnter: ifAdmin
        },
        {   path: '/tipo',
            component: () => import('@/components/types/type.vue'),
            name: 'type-new',
            beforeEnter: ifAdmin
        },
        // Tables
        {   path: '/mesas',
            component: () => import('@/components/tables/tables.vue'),
            name: 'tables',
            beforeEnter: ifAdmin
        },
        {   path: '/mesa/:id',
            component: () => import('@/components/tables/table.vue'),
            name: 'table',
            beforeEnter: ifAdmin
        },
        {   path: '/mesa',
            component: () => import('@/components/tables/table.vue'),
            name: 'table-new',
            beforeEnter: ifAdmin
        },
        // Participants
        {   path: '/participantes',
            component: () => import('@/components/participants/participants.vue'),
            name: 'participants',
            beforeEnter: ifAuthenticaded
        },
        {   path: '/participante/:id',
            component: () => import('@/components/participants/participant.vue'),
            name: 'participant',
            beforeEnter: ifAuthenticaded
        },
        {   path: '/participante',
            component: () => import('@/components/participants/participant.vue'),
            name: 'participant-new',
            beforeEnter: ifAuthenticaded
        },
        {
            path: "*",
            beforeEnter: invalidRoute
        }
    ]
});