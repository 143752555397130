<template>
    <div id="app">
        <!-- Main navbar -->
        <div class="navbar navbar-expand-md navbar-dark">
            <div class="navbar-brand wmin-0 mr-5">
                <router-link :to="{name: 'home'}" class="d-inline-block">
                    <img src="@/assets/images/logo_light.png" alt="">
                </router-link>
            </div>

            <div class="d-md-none">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-mobile">
                    <i class="icon-tree5"></i>
                </button>
            </div>
        
            <div class="collapse navbar-collapse" id="navbar-mobile">
                <ul class="navbar-nav">
                    <Navigation/>
                </ul>
            </div>

        </div>
        <!-- /main navbar -->

        <!-- Page content -->
        <div class="page-content pt-0">
            <!-- Main content -->
            <div class="content-wrapper">

                <!-- Content area -->
                    <vue-snotify/>
                    <router-view/>
                <!-- /content area -->

            </div>
            <!-- /main content -->

        </div>
        <!-- /page content -->

        <!-- Footer -->
        <div class="navbar navbar-expand-lg navbar-light">
            <span class="navbar-text">
                Copyright &copy; {{ new Date().getFullYear() }}. Confederação do Desporto de Portugal
            </span>
        </div>
        <!-- /footer -->
  </div>
</template>

<script>
// eslint-disable-next-line
import bootstrap from 'bootstrap';
// eslint-disable-next-line
import blockui from 'block-ui';
// eslint-disable-next-line
import dropdownhover from 'bootstrap-hover-dropdown';
// eslint-disable-next-line
import * as template from './assets/js/main/template.js';
// eslint-disable-next-line
import Navigation from './components/navigation.vue';

export default {
    name: 'App',
    components: {
        Navigation
    }
}
</script>

<style>
    @import 'https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900';
    @import "~@/assets/css/icons/icomoon/styles.min.css";
    @import "~@/assets/css/bootstrap.min.css";
    @import "~@/assets/css/bootstrap_limitless.min.css";
    @import "~@/assets/css/datatables/searchPanes.dataTables.min.css";
    @import "~@/assets/css/datatables/select.dataTables.min.css";
    @import "~@/assets/css/datatables/buttons.dataTables.min.css";
    @import "~@/assets/css//vue-multiselect.min.css";
    @import "~@/assets/css/layout.css";
    @import "~@/assets/css/components.min.css";
    @import "~@/assets/css/colors.min.css";
    @import "~vue-snotify/styles/material.css";
    @import "~@/assets/css/custom.css";
</style>