export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_ERROR = "GET_STATES_ERROR";
export const GET_STATE_DATA = "GET_STATE_DATA";
export const GET_STATE_DATA_SUCCESS = "GET_STATE_DATA_SUCCESS";
export const GET_STATE_DATA_ERROR = "GET_STATE_DATA_ERROR";
export const SAVE_STATE_DATA = "SAVE_STATE_DATA";
export const SAVE_STATE_DATA_SUCCESS = "SAVE_STATE_DATA_SUCCESS";
export const SAVE_STATE_DATA_ERROR = "SAVE_STATE_DATA_ERROR";
export const DELETE_STATE = "DELETE_STATE";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";
export const DELETE_STATE_ERROR = "DELETE_STATE_ERROR";