import { 
        GET_PARTICIPANTS, GET_PARTICIPANTS_SUCCESS, GET_PARTICIPANTS_ERROR,
        GET_PARTICIPANT_DATA, GET_PARTICIPANT_DATA_SUCCESS, GET_PARTICIPANT_DATA_ERROR,
        SAVE_PARTICIPANT_DATA, SAVE_PARTICIPANT_DATA_SUCCESS, SAVE_PARTICIPANT_DATA_ERROR,
        SAVE_PARTICIPANT_CHECKIN, SAVE_PARTICIPANT_CHECKIN_SUCCESS, SAVE_PARTICIPANT_CHECKIN_ERROR,
        GET_PARTICIPANT_SELECT_OPTIONS, GET_PARTICIPANT_SELECT_OPTIONS_SUCCESS, GET_PARTICIPANT_SELECT_OPTIONS_ERROR,
        DELETE_PARTICIPANT, DELETE_PARTICIPANT_SUCCESS, DELETE_PARTICIPANT_ERROR,
        SAVE_PARTICIPANTS_TABLE, SAVE_PARTICIPANTS_TABLE_SUCCESS, SAVE_PARTICIPANTS_TABLE_ERROR
       } from "../actions/participants";
import apiCall from "../../utils/api";

const state = { status: "" };

const getters = {
    getParticipantsActionStatus: state => state.status
};

const actions = {
    [GET_PARTICIPANTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_PARTICIPANTS);
            apiCall({ url: "gala/participants", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_PARTICIPANTS_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_PARTICIPANTS_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_PARTICIPANTS_ERROR);
                reject(err);
            });
        });
    },
    [GET_PARTICIPANT_DATA]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(GET_PARTICIPANT_DATA);
            apiCall({ url: "gala/participants/" + id, method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_PARTICIPANT_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_PARTICIPANT_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch(err => {
                commit(GET_PARTICIPANT_DATA_ERROR);
                reject(err);
            });
        });
    },
    [GET_PARTICIPANT_SELECT_OPTIONS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(GET_PARTICIPANT_SELECT_OPTIONS);
            apiCall({ url: "gala/participants/selectsoptions", method: "GET" })
            .then(resp => {
                if(resp.data.error) {
                    commit(GET_PARTICIPANT_SELECT_OPTIONS_ERROR);
                    resolve(resp);
                }
                else {
                    commit(GET_PARTICIPANT_SELECT_OPTIONS_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(GET_PARTICIPANT_SELECT_OPTIONS_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_PARTICIPANT_DATA]: ({commit}, data) => {
        let saveUrl = "";
        let saveMethod = "";
            if(data.id) {
                saveUrl = "gala/participants/" + data.id;
                saveMethod = "PUT";
            }
            else {
                saveUrl = "gala/participants";
                saveMethod = "POST";
            }
        return new Promise((resolve, reject) => {
            commit(SAVE_PARTICIPANT_DATA);
            apiCall({ url: saveUrl, data: data, method: saveMethod })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_PARTICIPANT_DATA_ERROR);
                    resolve(resp);
                }
                else {
                    commit(SAVE_PARTICIPANT_DATA_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_PARTICIPANT_DATA_ERROR);
                reject(err);
            });
        });
    },
    [DELETE_PARTICIPANT]: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            commit(DELETE_PARTICIPANT);
            apiCall({ url: "gala/participants/" + id, method: "DELETE" })
            .then(resp => {
                if(resp.data.error) {
                    commit(DELETE_PARTICIPANT_ERROR);
                    resolve(resp);
                }
                else {
                    commit(DELETE_PARTICIPANT_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(DELETE_PARTICIPANT_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_PARTICIPANTS_TABLE]: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            commit(SAVE_PARTICIPANTS_TABLE);
            apiCall({ url: "gala/participants/table", data: data, method: "POST" })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_PARTICIPANTS_TABLE_ERROR);
                    resolve(resp);
                }
                else {
                    commit(SAVE_PARTICIPANTS_TABLE_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_PARTICIPANTS_TABLE_ERROR);
                reject(err);
            });
        });
    },
    [SAVE_PARTICIPANT_CHECKIN]: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            commit(SAVE_PARTICIPANT_CHECKIN);
            apiCall({ url: "gala/participants/checkin", data: data, method: "POST" })
            .then(resp => {
                if(resp.data.error) {
                    commit(SAVE_PARTICIPANT_CHECKIN_ERROR);
                    resolve(resp);
                }
                else {
                    commit(SAVE_PARTICIPANT_CHECKIN_SUCCESS, resp);
                    resolve(resp);
                }
            })
            .catch((err) => {
                commit(SAVE_PARTICIPANT_CHECKIN_ERROR);
                reject(err);
            });
        });
    }
}

const mutations = {
    [GET_PARTICIPANTS]: state => {
        state.status = "A carregar...";
    },
    [GET_PARTICIPANTS_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_PARTICIPANTS_ERROR]: state => {
        state.status = "Erro";
    },
    [GET_PARTICIPANT_DATA]: state => {
        state.status = "A carregar...";
    },
    [GET_PARTICIPANT_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_PARTICIPANT_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_PARTICIPANT_DATA]: state => {
        state.status = "A salvar...";
    },
    [SAVE_PARTICIPANT_DATA_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_PARTICIPANT_DATA_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_PARTICIPANT_CHECKIN]: state => {
        state.status = "A salvar...";
    },
    [SAVE_PARTICIPANT_CHECKIN_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_PARTICIPANT_CHECKIN_ERROR]: state => {
        state.status = "Erro";
    },

    [GET_PARTICIPANT_SELECT_OPTIONS]: state => {
        state.status = "A carregar...";
    },
    [GET_PARTICIPANT_SELECT_OPTIONS_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [GET_PARTICIPANT_SELECT_OPTIONS_ERROR]: state => {
        state.status = "Erro";
    },
    [DELETE_PARTICIPANT]: state => {
        state.status = "A eliminar...";
    },
    [DELETE_PARTICIPANT_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [DELETE_PARTICIPANT_ERROR]: state => {
        state.status = "Erro";
    },
    [SAVE_PARTICIPANTS_TABLE]: state => {
        state.status = "A salvar...";
    },
    [SAVE_PARTICIPANTS_TABLE_SUCCESS]: state => {
        state.status = "Sucesso";
    },
    [SAVE_PARTICIPANTS_TABLE_ERROR]: state => {
        state.status = "Erro";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};