import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";
import auth from "./modules/auth";
import whitelistips from "./modules/whitelistips";
import blacklistips from "./modules/blacklistips";
import states from "./modules/states";
import types from "./modules/types";
import tables from "./modules/tables";
import participants from "./modules/participants";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        siteTitle: process.env.VUE_APP_TITLE,
        apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
        apiUsersImageFolder: process.env.VUE_APP_API_USERS_IMAGE_FOLDER
    },
    getters: {
        getSiteTitle: state => state.siteTitle,
        getApiBaseUrl: state => state.apiBaseUrl,
        getApiUsersImageFolder: state => state.apiUsersImageFolder,
    },
    modules: {
        auth,
        whitelistips,
        blacklistips,
        users,
        states,
        types,
        tables,
        participants
    },
    strict: process.env.APP_DEBUG
});
