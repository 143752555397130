export const GET_TABLES = "GET_TABLES";
export const GET_TABLES_SUCCESS = "GET_TABLES_SUCCESS";
export const GET_TABLES_ERROR = "GET_TABLES_ERROR";
export const GET_TABLE_DATA = "GET_TABLE_DATA";
export const GET_TABLE_DATA_SUCCESS = "GET_TABLE_DATA_SUCCESS";
export const GET_TABLE_DATA_ERROR = "GET_TABLE_DATA_ERROR";
export const SAVE_TABLE_DATA = "SAVE_TABLE_DATA";
export const SAVE_TABLE_DATA_SUCCESS = "SAVE_TABLE_DATA_SUCCESS";
export const SAVE_TABLE_DATA_ERROR = "SAVE_TABLE_DATA_ERROR";
export const GET_TABLE_SELECT_OPTIONS = "GET_TABLE_SELECT_OPTIONS";
export const GET_TABLE_SELECT_OPTIONS_SUCCESS = "GET_TABLE_SELECT_OPTIONS_SUCCESS";
export const GET_TABLE_SELECT_OPTIONS_ERROR = "GET_TABLE_SELECT_OPTIONS_ERROR";
export const DELETE_TABLE = "DELETE_TABLE";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";
export const DELETE_TABLE_ERROR = "DELETE_TABLE_ERROR";